// PRODUCT PREFIX

$product-prefix: "mmmAceMicrosite" !default;

// BREAKPOINTS

$mobile: 375px;
$mobile-max: 767px;
$tablet: 768px;
$tablet-max: 991px;
$desktop: 992px;
$desktop-large: 1440px;
$desktop-extra-large: 2250px;

// COLORS

$header-color: #041e42;
$main-color: #04214b;
$secondary-color: #0d0d0d;
$detail-color: #fdc500;
$disable-color: #c8c8c8;
$section-divider-color: #f6f6f6;
$white: #ffffff;

$modal-zindex: 21;

// CAROUSEL

// For full carousel variables use the _variables.scss
// at carousel component folder

$swiper-pagination-bullet--background: $white;
$swiper-pagination-bullet--size: 6px;
$swiper-play-control-color: $white;
$carousel-circle-arrow--background-color: #006797;
$carousel-circle-arrow--size: 42px;
$carousel__slide-copy-container--background-color: #eeece0;
