.#{product-prefix} {
  &-button {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 14px 18px;
    border: none;
    background-color: $main-color;
    font-family: Bogle, sans-serif;
    font-weight: bold;
    @include font-size(14);
    color: $white;
    appearance: none;
    transition: all 120ms ease-in-out;
    cursor: pointer;
    border: 1px solid transparent;

    &:after {
      @include selected-mark;
      position: absolute;
      display: none;
      top: -12px;
      right: -12px;
    }

    &.#{$product-prefix}-is-selected {
      border: 1px solid $main-color;
      background-color: $white;
      color: $main-color;

      &:after {
        display: block;
      }
    }

    @media not all and (pointer: coarse) {
      &:hover {
        border: 1px solid $main-color;
        background-color: $white;
        color: $main-color;
      }
    }
  }
}
