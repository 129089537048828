footer {
  color: $secondary-color;
  @include font-size(10);
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 0 5.87vw 27px 5.87vw;
}

.#{$product-prefix} {
  &-footer {
    border-top: 2px solid $secondary-color;

    ##{$product-prefix}-disclaimer {
      margin-top: 18px;
    }
  }

  &-copyright {
    display: inline-flex;
    line-height: normal;
    margin-top: 9px;

    img {
      margin-left: -28px;
      margin-right: 6px;
    }
  }
}
