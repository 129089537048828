$swiper-pagination-bullet--width: $swiper-pagination-bullet--size;
$swiper-pagination-bullet--height: $swiper-pagination-bullet--size;
$swiper-pagination-bullet--opacity: 1;
$swiper-pagination-bullet--margin: 0 6px;

$swiper-pagination-box--width: 178px;
$swiper-pagination-box--height: 28px;
$swiper-pagination-box--background-color: #313131;
$swiper-pagination-box--border-radius: 14px;

$swiper-play-control--height: 12px;
$swiper-play-control--margin-right: 12px;
$swiper-play-control--transition: 100ms all ease;
$swiper-play-control--border-color: transparent transparent transparent
  $swiper-play-control-color;
$swiper-play-control-hover--border-color: transparent transparent transparent
  $swiper-play-control-color;

$carousel__link--text-decoration: none;

$carousel__slide-copy-container--color: $main-color;
$carousel__slide-copy-container--text-align: center;

$carousel__slide-copy-container-tablet--text-align: left;

$carousel-logo--height: 35.5px;
$carousel-logo--width: 127px;
$carousel-logo--margin-bottom: 22px;

$carousel-logo-tablet--height: 41px;
$carousel-logo-tablet--width: 146px;

$carousel-logo-desktop-large--height: 63px;
$carousel-logo-desktop-large--width: 225px;
$carousel-logo-desktop-large--margin-bottom: 18px;

$carousel__slide-first-copy--font-weight: bold;
$carousel__slide-first-copy-tablet--font-weight: normal;

$carousel-circle-arrow--border-radius: 50%;
$carousel-circle-arrow--height: $carousel-circle-arrow--size;
$carousel-circle-arrow--width: $carousel-circle-arrow--size;

$carousel-circle-arrow-img--width: 20px;

$image-path: "../media/images";
