html {
  font-family: "Bogle";
  scroll-behavior: smooth;
}

.#{$product-prefix} {
  &-step {
    text-align: center;

    @media (max-width: $mobile-max) {
      &:first-of-type {
        padding-top: 41px !important;
      }
    }

    &:not(:last-of-type) {
      border-bottom: 50px solid $section-divider-color;
    }

    &-header {
      color: $secondary-color;
      @include font-size(18);
      letter-spacing: 0.9px;
      line-height: normal;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    &-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: auto;
      max-width: 450px;
      margin-top: 52px;

      @media (min-width: $tablet) {
        max-width: 660px;
      }
    }
  }

  &-item {
    > div,
    > button > div {
      &:after {
        @include selected-mark;

        display: none;
        position: absolute;
      }
    }
  }
}
