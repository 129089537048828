@import "variables";

.swiper {
  &-container {
    width: 100%;
    display: flex;
    justify-content: center;

    @media (min-width: $tablet) {
      height: auto;
    }
  }

  &-wrapper {
    height: 85%;

    @media (min-width: $tablet) {
      height: auto;
    }
  }

  &-pagination {
    position: relative;
    display: flex;
    justify-content: center;

    &-bullet {
      width: $swiper-pagination-bullet--width;
      height: $swiper-pagination-bullet--height;
      background: $swiper-pagination-bullet--background;
      opacity: $swiper-pagination-bullet--opacity;
      margin: $swiper-pagination-bullet--margin;
      outline: none;

      &-active {
        transform: scale(2, 2);
      }
    }

    &-box {
      position: absolute;
      bottom: 59.5%;
      width: $swiper-pagination-box--width;
      height: $swiper-pagination-box--height;
      border-radius: $swiper-pagination-box--border-radius;
      background-color: $swiper-pagination-box--background-color;
      display: flex;

      z-index: 2;
      align-items: center;
      justify-content: center;

      @media (min-width: $tablet) {
        bottom: 13%;
      }

      @media (min-width: $desktop) {
        bottom: 4.5%;
      }
    }
  }

  &-play-control {
    border: 0;
    background: transparent;
    box-sizing: border-box;
    width: 0;
    height: $swiper-play-control--height;
    margin-right: $swiper-play-control--margin-right;

    border-color: $swiper-play-control--border-color;
    transition: $swiper-play-control--transition;
    cursor: pointer;

    border-style: solid;
    border-width: 8px 0 8px 12px;

    &.paused {
      border-style: double;
      border-width: 0px 0 0px 12px;
    }

    &:hover {
      border-color: $swiper-play-control-hover--border-color;
    }
  }

  &-slide {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 140px;
    overflow: hidden;
    display: hidden;
  }

  &-lazy {
    height: auto;
    min-height: 221px;
    width: 100%;

    @media (min-width: $tablet) {
      height: 100%;
      min-height: 200px;
      width: 62.5%;
    }
  }
}

.#{$product-prefix} {
  &-carousel__link {
    text-decoration: $carousel__link--text-decoration;
    width: 100%;
  }

  &-carousel__content-container {
    display: flex;
    height: 378px;
    align-items: center;
    flex-direction: column;

    @media (min-width: $tablet) {
      flex-direction: row-reverse;
      height: 100%;
      min-height: 200px;
    }
  }

  &-carousel__slide-copy-container {
    z-index: 1; // greather than the default set by the map lib
    flex-direction: column;
    align-items: center;
    display: flex;
    height: 14rem;
    margin-top: -50px;
    width: 21.5rem;
    padding: 22px 10px 40px;
    background-color: $carousel__slide-copy-container--background-color;
    text-align: $carousel__slide-copy-container--text-align;
    color: $carousel__slide-copy-container--color;

    @media (min-width: $tablet) {
      align-items: flex-start;
      flex-direction: column-reverse;
      justify-content: space-between;
      height: 100%;
      width: 37.5%;
      margin-top: 0;
      padding: 18px 0 0 32px;
      text-align: $carousel__slide-copy-container-tablet--text-align;
    }

    @media (min-width: $desktop) {
      padding: 28px 0 0 98px;
      & > div {
        max-width: 85%;
      }
    }

    @media (min-width: $desktop-large) {
      padding: 28px 0 0 90px;
    }
  }

  &-logo {
    height: $carousel-logo--height;
    width: auto;
    margin-bottom: $carousel-logo--margin-bottom;

    @media (min-width: $tablet) {
      max-height: $carousel-logo-tablet--height;
      max-width: 70%;
      height: auto;
      width: auto;
    }

    @media (min-width: $desktop-large) {
      max-height: $carousel-logo-desktop-large--height;
      max-width: 83%;
      margin-bottom: $carousel-logo-desktop-large--margin-bottom;
    }
  }

  &-carousel__slide-first-copy {
    font-size: convert-px-to-rem(16);
    line-height: 1.33;
    letter-spacing: 0.9px;
    margin-bottom: 10px;
    font-weight: $carousel__slide-first-copy--font-weight;

    @media (min-width: $tablet) {
      font-weight: $carousel__slide-first-copy-tablet--font-weight;
      line-height: 1.2;
      letter-spacing: 0.85px;
    }

    @media (min-width: $desktop) {
      font-size: convert-px-to-rem(32);
      line-height: 1.25;
      letter-spacing: 1.6px;
      margin-bottom: 5px;
    }
  }

  &-carousel__slide-second-copy {
    font-size: convert-px-to-rem(13);
    line-height: 1.2;
    letter-spacing: 0.75px;

    @media (min-width: $desktop) {
      font-size: convert-px-to-rem(22);
      line-height: 1.36;
      letter-spacing: 1.1px;
    }
  }

  &-carousel__slide-third-copy {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: convert-px-to-rem(13);
    line-height: 1.2;
    letter-spacing: 0.75px;

    @media (min-width: $tablet) {
      position: unset;
      transform: unset;
      font-size: convert-px-to-rem(10);
      margin-top: 10px;
    }

    @media (min-width: $desktop) {
      font-size: convert-px-to-rem(15);
    }
  }

  &-carousel__slide-disclaimer {
    font-size: convert-px-to-rem(10);
    line-height: 1.64;
    letter-spacing: 0.0375rem;
    margin-top: convert-px-to-rem(7);

    @media (min-width: $tablet) {
      line-height: 1.4;
      margin: 10px 0;
    }

    @media (min-width: $desktop) {
      line-height: 1.2;
    }
  }

  &-circle-arrow {
    align-items: center;
    justify-content: center;
    background-color: $carousel-circle-arrow--background-color;
    border-radius: $carousel-circle-arrow--border-radius;
    bottom: 20px;
    display: none;
    left: 27.7%;
    position: absolute;
    height: $carousel-circle-arrow--height;
    width: $carousel-circle-arrow--width;

    @media (min-width: $tablet) {
      display: flex;
    }

    @media (min-width: $desktop) {
      left: 33%;
      bottom: 20px;
    }

    & img {
      width: $carousel-circle-arrow-img--width;
    }
  }
}

// To fix the store buttons position between 768 and 850px, the calc adds
// a correction factor, based on a part (4.8) of the difference between
// the current screen size (100vw) and the reference screen size (768px)
$store-button-tablet-position: calc(48.5vw + ((100vw - 768px) / 4.8));
.mmmAceMicrosite-slide-stores-wrapper {
  @media (min-width: $tablet) {
    height: 9.1vw;
    position: absolute;
    width: 22.5vw;
    left: 40vw;
    top: 8.3vw;
    background: url("#{$image-path}/carousel/filtrete_238x236.png") no-repeat
      top left;
    background-size: contain;
  }
}
.mmmAceMicrosite-slide-store-link_app-store-link {
  position: absolute;
  right: 54.5vw;
  top: 38px;

  img {
    width: 137px;
  }

  @media (min-width: $tablet) {
    left: auto;
    top: 0;
    right: 0;

    img {
      width: 95px;
    }
  }

  @media (min-width: 852px) {
    img {
      width: 11.4vw;
    }
  }
}

.mmmAceMicrosite-slide-store-link_google-play-link {
  position: absolute;
  left: 49.5vw;
  top: 38px;

  img {
    width: 156px;
  }

  @media (min-width: $tablet) {
    left: auto;
    top: auto;
    bottom: -2px;
    right: 0px;

    img {
      width: 96px;
    }
  }

  @media (min-width: 852px) {
    img {
      width: 11.4vw;
    }
  }
}
