.#{$product-prefix} {
  &-header {
    width: 100%;
    height: 63px;
    background-color: $header-color;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      margin: 0 5%;

      @media (min-width: $tablet) {
        margin: 0 32px;
      }

      @media (min-width: $desktop) {
        margin: 0 98px;
      }
    }

    .skip-link {
      color: white;
      width: 10rem;
      margin-left: -5rem;
      left: 50%;
      position: absolute;
      top: -2px;
      transform: translateY(-100%);
      transition: transform 0.3s;
      text-decoration: none;

      &:focus {
        transform: translateY(0%);
      }
    }
  }

  &-logo-walmart {
    height: 33px;
  }

  &-logo-3m {
    height: 30px;
  }
}
