.#{$product-prefix} {
  &-step--image {
    background-color: $section-divider-color;
    padding: 29px 0 77px;
    border-bottom: none !important;

    @media (min-width: $tablet) {
      padding: 10px 0 60px;
    }

    @media (min-width: $desktop) {
      padding: 45px 0 93px;
    }

    .#{$product-prefix} {
      &-step-items {
        @media (min-width: $desktop) {
          max-width: inherit;
        }
      }

      &-is-selected > div {
        p {
          border-color: $main-color;
        }

        &:after {
          display: block;
        }
      }

      &-item:not(:last-of-type) {
        margin-right: 20px;

        @media (min-width: $tablet) {
          margin-right: 30px;
        }

        @media (max-width: $tablet-max) {
          margin-bottom: 30px;
        }
      }

      @media (max-width: $tablet-max) {
        &-item:nth-child(even) {
          margin-right: 0;
        }
      }

      &-image-selector {
        padding-bottom: 26px;
        position: relative;
        transition: border-color 120ms ease-in-out;
        display: flex;
        justify-content: center;

        @media (min-width: $tablet) {
          padding-bottom: 16px;
        }

        div {
          display: flex;
          justify-content: center;
          overflow: hidden;
          height: 170px;
          width: 135px;

          @media (min-width: $mobile) {
            width: 165px;
          }

          @media (min-width: $tablet) {
            height: 184px;
            width: 288px;
          }
        }

        img {
          width: 288px;
        }

        p {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 0;
          width: 143px;
          height: 40px;
          background-color: $white;
          border-bottom: 2px solid $detail-color;
          color: $secondary-color;

          @media (min-width: $tablet) {
            height: 44px;
            width: 230px;
          }
        }

        &::after {
          top: 146px;
          right: 6px;

          @media (min-width: $tablet) {
            top: 144px;
            right: 17px;
          }
        }

        &:hover {
          cursor: pointer;

          > p {
            border-color: $main-color;
          }
        }
      }
    }
  }
}
