##{$product-prefix}-collapseController {
  position: absolute;
  visibility: hidden;

  &:checked ~ .#{$product-prefix}-reference__header:after {
    transform: rotate(180deg);
  }

  &:checked ~ .#{$product-prefix}-reference__content {
    max-height: 0;
  }
}

.#{$product-prefix} {
  &-reference {
    &__header {
      position: relative;
      display: block;
      padding: 6px 0;
      margin-bottom: 32px;
      border-bottom: 2px solid #fdc500;
      cursor: pointer;

      &:after {
        position: absolute;
        top: 12px;
        right: 0;
        display: block;
        width: 14px;
        height: 7px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='7' viewBox='0 0 14 7'%3E%3Cpath fill='%23FDC500' fill-rule='evenodd' stroke='%23FDC500' d='M7 1.278l5.42 5.127a.352.352 0 0 0 .48 0 .308.308 0 0 0 0-.454L7.24.595a.352.352 0 0 0-.481 0L1.1 5.95a.306.306 0 0 0 0 .452.352.352 0 0 0 .48 0L7 1.278z' opacity='.776'/%3E%3C/svg%3E%0A");
        content: "\00a0";
        transition: transform 200ms ease-in-out;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      max-height: 700px;
      transition: max-height 300ms cubic-bezier(1, 0, 0, 1);
      overflow: hidden;

      @media (min-width: $tablet) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    &__title {
      font-family: Bogle;
      @include font-size(15);
      color: $secondary-color;

      &::selection {
        background: none;
      }
    }

    &__item {
      display: flex;
      margin-bottom: 45px;

      p {
        @include font-size(13);
        line-height: 1.31;
      }

      @media (min-width: $tablet) {
        flex-grow: 2;
        flex-basis: calc(50% - 62px);

        &:nth-child(odd) {
          margin: 0 62px 56px 0;
        }
      }
    }

    &__icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 19px;
      font-weight: bold;
      @include font-size(14);
      text-align: center;
      text-transform: uppercase;
      white-space: nowrap;

      img {
        margin-bottom: 11px;
      }
    }
  }
}
