@import "reset";
@import "variables";

:root {
  font-size: calc(
    16px + (24 - 16) * (100vw - #{$mobile}) /
      (#{strip-unit($mobile-max)} - #{strip-unit($mobile)})
  );

  @media (min-width: $tablet) {
    font-size: calc(
      16px + (24 - 16) * (100vw - #{$tablet}) /
        (#{strip-unit($tablet-max)} - #{strip-unit($tablet)})
    );
  }

  @media (min-width: $desktop) {
    font-size: 16px;
  }

  @media (min-width: $desktop-large) {
    font-size: calc(
      16px + (24 - 16) * (100vw - #{$desktop}) /
        (2560 - #{strip-unit($desktop)})
    );
  }
}

button,
a,
li {
  &:focus {
    outline: 1px dotted #333;
  }
}
