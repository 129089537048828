.#{$product-prefix} {
  &-step--icon {
    padding: 79px 0 39px;

    @media (min-width: $tablet) {
      padding: 60px 0 0;
    }

    @media (min-width: $desktop) {
      padding: 94px 0 7px;
    }

    .#{$product-prefix} {
      &-is-selected {
        & > div,
        & > button > div {
          background-color: $white;

          & img {
            filter: brightness(1) invert(0);
          }

          &:after {
            display: block;
          }
        }
      }

      &-item-disabled {
        &:hover > div,
        &:hover > button > div {
          background-color: $disable-color;
          cursor: not-allowed;

          & img {
            filter: brightness(0) invert(1);
          }
        }

        & > div {
          background-color: $disable-color;
          border: 2px solid $disable-color;
        }

        & > p {
          color: $disable-color;
        }
      }

      &-item {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 37px;

        @media (min-width: $tablet) {
          margin: 0 19px 59px;
        }

        @media (min-width: $desktop) {
          margin: 0 19px 84px;
        }
      }

      &-icon-selector {
        &--button {
          background: transparent;
          border: 0;
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 125px;
        }

        background-color: $main-color;
        border: 2px solid $main-color;
        border-radius: 50%;
        display: flex;
        height: 80px;
        justify-content: center;
        margin-bottom: 7px;
        position: relative;
        width: 80px;

        + p {
          @include font-size(15);
          line-height: 1.2;
          width: 125px;
        }

        & img {
          pointer-events: none;
          filter: brightness(0) invert(1);

          &::selection {
            background-color: transparent;
          }
        }

        &:hover {
          background-color: $white;
          color: $main-color;
          cursor: pointer;

          & img {
            filter: brightness(1) invert(0);
          }
        }

        &:after {
          left: 56px;
          top: -3px;
        }
      }
    }
  }
}
