@mixin selected-mark {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $main-color
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDE1IDExIj4KICAgIDxwYXRoIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTS4xNjUgNi4wNUEuNTk0LjU5NCAwIDAgMSAwIDUuNjY1YzAtLjExLjA1NS0uMjc1LjE2NS0uMzg1bC43Ny0uNzdjLjIyLS4yMi41NS0uMjIuNzcgMGwuMDU1LjA1NUw0Ljc4NSA3LjgxYy4xMS4xMS4yNzUuMTEuMzg1IDBMMTIuNTQuMTY1aC4wNTVjLjIyLS4yMi41NS0uMjIuNzcgMGwuNzcuNzdjLjIyLjIyLjIyLjU1IDAgLjc3bC04LjggOS4xM0EuNS41IDAgMCAxIDQuOTUgMTFhLjUuNSAwIDAgMS0uMzg1LS4xNjVsLTQuMjktNC42Mi0uMTEtLjE2NXoiLz4KPC9zdmc+Cg==")
    center 7px no-repeat;
  content: "\00a0";
}
