.modal-backdrop {
  background: rgba(29, 35, 44, 0.85);
  z-index: $modal-zindex;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  position: fixed;
  right: 0;
  top: 0;
}

.modal {
  z-index: $modal-zindex;
  width: calc(100% - 48px);
  left: 50%;
  outline: none;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 985px;
}

.modal-header {
  background-color: $main-color;
  color: $white;
  line-height: 1.2;
  letter-spacing: 0.75px;
  text-align: center;
  padding: 16px 25px;

  & > h1 {
    font-weight: bold;
    @include font-size(22);
    line-height: 1.22;
  }
  & > p {
    @include font-size(15);
  }
}

.modal-body {
  position: relative;
}

.modal-close-button {
  z-index: $modal-zindex;
  cursor: pointer;
  border-radius: 100%;
  outline: none;
  position: absolute;
  background: $white;
  padding: 10px;
  height: 46px;
  width: 46px;
  top: -18px;
  right: -15px;
  border: none;
}

@media (min-width: $tablet) {
  .modal {
    width: 80%;
  }
}
