.#{$product-prefix} {
  &-step--round {
    padding: 80px 0 79px;
    text-transform: uppercase;

    @media (min-width: $tablet) {
      padding: 58px 0 59px;
    }

    @media (min-width: $desktop) {
      padding: 92px 0 92px;
    }

    .#{$product-prefix}-item:not(:last-of-type) {
      margin-bottom: 24px;

      @media (min-width: $tablet) {
        margin-bottom: 0;
        margin-right: 48px;
      }
    }

    .#{$product-prefix}-is-selected {
      & > div {
        background-color: $white;
        color: $main-color;

        &:after {
          display: block;
        }
      }
    }

    .#{$product-prefix}-round-selector {
      align-items: center;
      background-color: $main-color;
      border: 2px solid $main-color;
      border-radius: 32px;
      color: $white;
      display: flex;
      @include font-size(18);
      font-weight: bold;
      justify-content: center;
      letter-spacing: 0.9px;
      height: 44px;
      position: relative;
      width: 262px;

      &:hover {
        background-color: $white;
        color: $main-color;
        cursor: pointer;
      }

      &:after {
        left: 241px;
        top: -10px;
      }
    }
  }
}
