.#{$product-prefix} {
  &-products {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 79px 0 74px;

    @media (min-width: $desktop) {
      padding-top: 92px;
    }

    h2 {
      @include font-size(21);
      font-weight: bold;
      letter-spacing: 1.05px;

      &.no-products-found {
        max-width: 700px;
        margin: auto 10% 5% 10%;

        @media (min-width: $desktop) {
          margin: auto 10% 3% 10%;
        }
      }
    }

    &_container {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: -6px;
      margin-bottom: 26px;
      max-width: 1129px;

      @media (min-width: $tablet) {
        margin-bottom: -11px;
      }

      @media (min-width: $desktop) {
        margin-bottom: 14px;
      }
    }
  }

  &-card {
    align-items: center;
    background-color: $white;
    box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.13);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 325px;
    margin: 0 20px 51px;
    padding: 14px 16px 0;
    width: 285px;

    @media (min-width: $tablet) {
      margin-bottom: 74px;
    }

    @media (min-width: $desktop) {
      margin-bottom: 50px;
    }

    a {
      text-decoration: none;
    }

    & .#{$product-prefix} {
      &-product {
        &_image {
          height: 159px;
          margin-bottom: 15px;
          width: 253px;

          & img {
            height: 159px;
          }
        }

        &_name {
          color: $secondary-color;
          @include font-size(16);
          font-weight: bold;
          line-height: normal;
          margin-bottom: 7px;
          text-align: center;
          width: 100%;
          & h2 {
            @include font-size(16);
            height: 2.9rem;

            display: block;
            display: -webkit-box;
            max-width: 400px;
            margin: 0 auto;
            line-height: 1.4;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            @media (min-width: $desktop-large) {
              @include font-size(14);
              height: 2.5rem;
            }

            @media (min-width: $desktop-extra-large) {
              @include font-size(12);
              height: 2rem;
            }
          }
        }

        &:hover {
          .#{$product-prefix}-button-selector {
            background-color: $white;
            color: $main-color;
          }
        }
      }
    }
  }

  &-button-selector {
    background-color: $main-color;
    border: 2px solid $main-color;
    border-radius: 41px;
    color: $white;
    @include font-size(18);
    font-weight: bold;
    line-height: 40px;
    justify-content: center;
    text-decoration: none;
    width: 229px;
    margin: auto;

    @media (min-width: $desktop-extra-large) {
      @include font-size(12);
    }
  }

  &-restart-selector {
    background-color: $white;
    border: solid 1px $main-color;
    border-radius: 32px;
    color: $main-color;
    cursor: pointer;
    font-family: "Bogle";
    @include font-size(18);
    font-weight: bold;
    height: 44px;
    letter-spacing: 0.9px;
    width: 262px;

    @media (min-width: $tablet) {
      margin-bottom: 24px;
    }

    @media (min-width: $desktop-extra-large) {
      @include font-size(15);
    }

    &:hover {
      background-color: $main-color;
      color: $white;
    }
  }
}
